<template>
  <div class="news-view">
    <div class="news-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
        <h2 class="news-view__title">{{ $t('news_view.news') }}</h2>
<!--        <Tabs>-->
<!--          <router-link to="/events"-->
<!--                       :class="$route.path === '/events' ? 'tabs__active' : ''"-->
<!--                       class="tabs__tab">События-->
<!--          </router-link>-->
<!--          <router-link to="/news"-->
<!--                       :class="$route.path === '/news' ? 'tabs__active' : ''"-->
<!--                       class="tabs__tab">Новости-->
<!--          </router-link>-->
<!--          <router-link to="/stories"-->
<!--                       :class="$route.path === '/stories' ? 'tabs__active' : ''"-->
<!--                       class="tabs__tab">Сюжеты-->
<!--          </router-link>-->
<!--        </Tabs>-->
        <Row :class="!$mobile ? '-m-15' : ''"
             wrap>
          <NewsCard v-for="news in $news.data"
                class="grid-md-4 grid-lg-4 news-view__new"
                :key="news.id"
                :params="news"/>
        </Row>
        <Row v-if="$news.links.next">
          <Button v-on:click.native="nextPage"
                  class="trips-view__more"
                  color="like-link">{{ $t('service.show_more') }}
          </Button>
        </Row>
      </Section>
      <Section limiter>
        <Share :url="`${$host}/stories`"
               :title="share.title"
               :description="share.description"/>
      </Section>
      <Section v-if="$showIfDefaultCity"
               :limiter="!$mobile">
        <FormationPortal :backgroundImage="$laptop ?
          require('@/assets/images/mobile.jpg') : ''">
          <template slot="formation-portal-title">
            <span class="formation-portal__title">{{ $t('home.app.p_1') }} <br>
              {{ $t('home.app.p_2') }}</span>
          </template>
          <component :is="$mobile ? 'Column' : 'Row'"
                     :full="$mobile"
                     :class="$mobile ? '' : '-m-15'">
            <a class="home-view__app-icon"
               :href="appStoreHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/appstore.svg')" alt/>
            </a>
            <a class="home-view__app-icon"
               :href="googlePlayHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/googleplay.svg')" alt/>
            </a>
          </component>
        </FormationPortal>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsView',
  data() {
    return {
      appStoreHref: process.env.VUE_APP_DEFAULTH_MOBILE_APPLE_LINK,
      googlePlayHref: process.env.VUE_APP_DEFAULTH_MOBILE_ANDROID_LINK,
    };
  },
  methods: {
    nextPage() {
      this.$store.dispatch('GET_NEWS_FROM_SERVER_WITH_PARAMS', {
        params: {
          count: 6,
          resolution: 'medium',
          city_id: this.$city.id,
          page: this.$news.links.next.split('?')[1].split('=')[1],
          lang: this.$i18n.locale,
        },
      });
    },
  },
  computed: {
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/news',
        title: this.$i18n.t('menu.news'),
      }];
    },
  },
  created() {
    this.$store.dispatch('GET_NEWS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 6,
        resolution: 'medium',
        city_id: this.$city.id,
        lang: this.$i18n.locale,
      },
    });
  },
};
</script>
